import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'

import {
    MAX_CONTENT_WIDTH,
    MAX_CONTENT_WIDTH_SML,
} from '../../constants/global'

export const contentSectionWidths = {
    small: 'small',
    full: 'full',
}

export const contentSectionSpacing = {
    small: 'small',
    medium: 'medium',
    large: 'large',
}

const useStyles = makeStyles((theme) => ({
    container: {
        margin: (props) => {
            if (props.spacing === contentSectionSpacing.small) {
                return `${theme.spacing(5)}px auto 0 auto`
            }

            if (props.spacing === contentSectionSpacing.medium) {
                return `${theme.spacing(10)}px auto 0 auto`
            }

            if (props.spacing === contentSectionSpacing.large) {
                return `${theme.spacing(20)}px auto 0 auto`
            }

            return '0 auto'
        },
        padding: (props) => {
            if (props.spacing === contentSectionSpacing.small) {
                return `${theme.spacing(5)}px ${theme.spacing(3)}px`
            }

            if (props.spacing === contentSectionSpacing.medium) {
                return `${theme.spacing(10)}px ${theme.spacing(3)}px`
            }

            if (props.spacing === contentSectionSpacing.large) {
                return `${theme.spacing(20)}px ${theme.spacing(3)}px`
            }

            return `0 ${theme.spacing(3)}px`
        },
        maxWidth: (props) => {
            if (props.width === contentSectionWidths.small) {
                return MAX_CONTENT_WIDTH_SML
            }

            if (props.width === contentSectionWidths.full) {
                return 'none'
            }

            return MAX_CONTENT_WIDTH
        },

        backgroundColor: (props) => props.backgroundColor,
    },
}))

function ContentSection({
    children,
    className,
    width,
    backgroundColor,
    spacing,
    ...other
}) {
    const props = { width, backgroundColor, spacing }
    const classes = useStyles(props)

    return (
        <section
            className={classNames(classes.container, className)}
            {...other}
        >
            {children}
        </section>
    )
}

ContentSection.defaultProps = {
    backgroundColor: 'transparent',
}

ContentSection.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    width: PropTypes.oneOf([
        contentSectionWidths.small,
        contentSectionWidths.full,
    ]),
    spacing: PropTypes.oneOf([
        contentSectionSpacing.small,
        contentSectionSpacing.medium,
        contentSectionSpacing.large,
    ]),
    backgroundColor: PropTypes.string,
}

export default ContentSection
