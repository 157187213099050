import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

export const flcButtonVariants = {
    secondary: 'secondary',
    text: 'text',
}

const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: 'none',
        margin: theme.spacing(1),
        color: theme.palette.text.primary,
        fontSize: theme.typography.pxToRem(18),
        backgroundColor: 'rgba(0,0,0,0.3)',
        border: `1px solid ${theme.palette.text.primary}`,
        padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,

        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.6)',
        },
    },
    [flcButtonVariants.secondary]: {
        border: 'none',
    },
    [flcButtonVariants.text]: {
        border: 'none',
        backgroundColor: 'transparent',

        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.2)',
        },
    },
}))

function FlcButton({ className, text, onClick, variant, ...other }) {
    const classes = useStyles()

    return (
        <Button
            className={classnames(
                classes.button,
                variant === flcButtonVariants.secondary &&
                    classes[flcButtonVariants.secondary],
                variant === flcButtonVariants.text &&
                    classes[flcButtonVariants.text],
                className
            )}
            onClick={onClick}
            endIcon={
                variant === flcButtonVariants.text ? <NavigateNextIcon /> : null
            }
            {...other}
        >
            {text}
        </Button>
    )
}

FlcButton.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    variant: PropTypes.oneOf([
        null,
        flcButtonVariants.secondary,
        flcButtonVariants.text,
    ]),
}

export default FlcButton
